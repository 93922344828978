import { render, staticRenderFns } from "./UserInfoRef.vue?vue&type=template&id=9f2c9234&scoped=true&"
import script from "./UserInfoRef.vue?vue&type=script&lang=js&"
export * from "./UserInfoRef.vue?vue&type=script&lang=js&"
import style0 from "./UserInfoRef.vue?vue&type=style&index=0&id=9f2c9234&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f2c9234",
  null
  
)

export default component.exports